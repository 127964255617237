import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import EmailVerification  from "./components/emailVerification";

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


const Landing = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      {/* <Features data={landingPageData.Features} /> */}
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Gallery data={landingPageData.Gallery} />
      {/* <Testimonials data={landingPageData.Testimonials} /> */}
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
}


const SuccessRegister = () => {
  return (
    <div>
      <h1>¡Registro exitoso!</h1>
      <p>Podes cerrar esta ventana y volver a la aplicación</p>
    </div>
  );
}

const AboutData = () => {
  return (
    <div>
      <h1>Política de Privacidad</h1>
    <p><strong>Última actualización:</strong> [Fecha]</p>
    
    <h2>1. Introducción</h2>
    <p>En <strong>Sembrala</strong> ("nosotros", "nuestro" o "la empresa"), nos comprometemos a proteger la privacidad de nuestros usuarios. Esta Política de Privacidad explica cómo recopilamos, usamos, almacenamos y protegemos tu información cuando utilizas nuestra aplicación de billetera digital ("wallet").</p>
    <p>Al acceder o utilizar nuestros servicios, aceptas los términos de esta política.</p>
    
    <h2>2. Información que Recopilamos</h2>
    <ul>
        <li><strong>Datos personales:</strong> Nombre, apellido, DNI/CUIT, correo electrónico, número de teléfono y dirección.</li>
        <li><strong>Información financiera:</strong> CBU, CVU, historial de transacciones y saldo disponible.</li>
        <li><strong>Información del dispositivo:</strong> Dirección IP, modelo del dispositivo, sistema operativo y versión de la app.</li>
        <li><strong>Ubicación:</strong> Solo si otorgas permiso, para mejorar la seguridad de las transacciones.</li>
        <li><strong>Datos de uso:</strong> Información sobre cómo interactúas con nuestra aplicación y servicios.</li>
    </ul>
    
    <h2>3. Uso de la Información</h2>
    <p>Usamos tu información para:</p>
    <ul>
        <li>Verificar tu identidad y prevenir fraudes.</li>
        <li>Procesar transacciones y brindarte servicios financieros.</li>
        <li>Mejorar la experiencia del usuario en nuestra aplicación.</li>
        <li>Cumplir con regulaciones financieras y requisitos legales.</li>
        <li>Comunicarnos contigo sobre cambios en nuestros servicios.</li>
    </ul>
    
    <h2>4. Compartición de Información</h2>
    <p>Podemos compartir tu información con:</p>
    <ul>
        <li><strong>Proveedores de servicios:</strong> Empresas que procesan pagos o brindan soporte tecnológico.</li>
        <li><strong>Entidades gubernamentales:</strong> Si es requerido por ley o una orden judicial.</li>
        <li><strong>Empresas afiliadas:</strong> Solo con tu consentimiento previo.</li>
    </ul>
    <p><strong>No vendemos ni alquilamos tu información a terceros.</strong></p>
    
    <h2>5. Almacenamiento y Seguridad</h2>
    <p>
        Tu información se almacena en servidores seguros con cifrado avanzado. Implementamos medidas para prevenir accesos no autorizados. 
        No almacenamos información de tarjetas de crédito; trabajamos con proveedores certificados.
    </p>
    
    <h2>6. Derechos del Usuario</h2>
    <p>Como usuario de <strong>Sembrala</strong>, tienes derecho a:</p>
    <ul>
        <li>Acceder, corregir o eliminar tus datos personales.</li>
        <li>Oponerte al uso de tu información con fines de marketing.</li>
        <li>Retirar tu consentimiento en cualquier momento.</li>
    </ul>
    <p>Para ejercer estos derechos, contáctanos en <strong>info@sembrala.com</strong>.</p>
    
    <h2>7. Cookies y Tecnologías de Seguimiento</h2>
    <p>Utilizamos cookies y herramientas de análisis para mejorar nuestro servicio. Puedes deshabilitar las cookies desde la configuración de tu navegador o dispositivo.</p>
    
    <h2>8. Cambios en la Política de Privacidad</h2>
    <p>Podemos actualizar esta política en cualquier momento. Si realizamos cambios importantes, te notificaremos a través de la aplicación o por correo electrónico.</p>
    
    <h2>9. Contacto</h2>
    <p>Si tienes preguntas sobre esta Política de Privacidad, puedes contactarnos en:</p>
    <p><strong>📧 Correo electrónico:</strong> info@sembrala.com</p>
    </div>
  );
}

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/about" element={<AboutData />} />
          <Route path="/" element={<Landing />} />
          <Route path="/verify-email" element={<EmailVerification />} />
          <Route path="/success-register" element={<SuccessRegister />} />
        </Routes>
      </div>
    </Router>
  );
};



export default App;
