import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, applyActionCode} from 'firebase/auth';
import { initializeApp } from 'firebase/app';

const EmailVerification = () => {
  const location = useLocation();
  const [verificationStatus, setVerificationStatus] = useState('Verificando...');
  const navigate = useNavigate();

  const firebaseConfig = {
    apiKey: "AIzaSyAVOhCdeFvUGBaWWbPKfG8vOuWb-HFCL2Y",
    projectId: "sembrala-test2",
    messagingSenderId: "531871527067",
    appId: "1:531871527067:web:8438650c32eadb8e29563e",
    measurementId: "G-KV9D5MJF0Y"
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  useEffect(() => {
    const verifyEmail = async () => {
      const queryParams = new URLSearchParams(location.search);
      const oobCode = queryParams.get('oobCode');
      const mode = queryParams.get('mode');
  
      if (mode === 'verifyAndChangeEmail' && oobCode) {
        try {
          await applyActionCode(auth, oobCode);
          setVerificationStatus('¡Correo electrónico verificado correctamente!');
        } catch (error) {
          console.error('Error al aplicar el código de verificación:', error);
          setVerificationStatus('Error al verificar el correo electrónico.');
        }
      } else {
        setVerificationStatus('Link inválido o incompleto.');
      }
    };
  
    verifyEmail();
  }, [location.search, auth, navigate]);

  return (
    <div className="email-verification-container">
      <h1>Verificación de Correo Electrónico</h1>
      <p>{verificationStatus}</p>
    </div>
  );
};

export default EmailVerification;
